import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@env/environment';
import { SignInMethods, UpdateSignInMethods } from '@models/partner/sign-in-method.interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PlatformParametersWebservice {
  readonly #http = inject(HttpClient);
  readonly #baseUrl = `${environment.api.obendy}/v1/partner-revision`;

  getSignInMethods(partnerId: string): Observable<SignInMethods> {
    return this.#http.get<SignInMethods>(`${this.#baseUrl}/${partnerId}/sign-in-methods`);
  }

  putSignInMethods(partnerId: string, signInMethods: UpdateSignInMethods): Observable<SignInMethods> {
    return this.#http.put<SignInMethods>(`${this.#baseUrl}/${partnerId}/sign-in-methods`, signInMethods);
  }
}
